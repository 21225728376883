export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - services",
                key: "header_item_services"
            },
            {
                label: "Header - about",
                key: "header_item_about"
            },
            {
                label: "Header - news",
                key: "header_item_news"
            },
            {
                label: "Header - staff",
                key: "header_item_staff"
            },
            {
                label: "Header - staff",
                key: "header_item_contact"
            },
            {
                label: "Header - dank-systems",
                key: "header_item_dank-systems"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - main apply",
                key: "homepage_main_block_apply"
            },
            {
                label: "Homepage - info btn",
                key: "homepage_more_info_btn"
            },
            {
                label: "Homepage - projects title",
                key: "homepage_projects_title"
            },
            {
                label: "Homepage - projects subtitle",
                key: "homepage_projects_subtitle"
            },
            {
                label: "Homepage - projects description",
                key: "homepage_projects_description"
            },
            {
                label: "Homepage - contact title",
                key: "homepage_contact_title"
            },
            {
                label: "Homepage - contact description",
                key: "homepage_contact_description"
            },
            {
                label: "Homepage - contact placeholder email",
                key: "homepage_contact_placeholder_email"
            },
            {
                label: "Homepage - contact button send",
                key: "homepage_contact_button_send"
            },
            {
                label: "Homepage - works title",
                key: "homepage_works_title"
            },
            {
                label: "Homepage - works description",
                key: "homepage_works_description"
            },
            {
                label: "Homepage - works button",
                key: "homepage_works_button"
            },
            {
                label: "Homepage - sponsors title",
                key: "homepage_sponsors_title"
            },
            {
                label: "Homepage - sponsors block title",
                key: "homepage_sponsors_block_title"
            },
            {
                label: "Homepage - maintenance subtitle",
                key: "homepage_maintenance_subtitle"
            },
            {
                label: "Homepage - maintenance title",
                key: "homepage_maintenance_title"
            },
            {
                label: "Homepage - maintenance description",
                key: "homepage_maintenance_description"
            },
            {
                label: "Homepage - partners title",
                key: "homepage_partners_block_title"
            },
        ]


    },
    services:{
        pageName:"Services",
        list:[
            {
                label:"Service - information title",
                key:"service_information_title"
            },
            {
                label:"Service - mainBlock title",
                key:"service_mainBlock_title"
            },
            {
                label:"About - title",
                key:"about_us_title"
            },
            {
                label:"Service - linkBtn title",
                key:"service_linkBtn_title"
            },
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - section sites title",
                key: "footer_section_sites_title"
            },
            {
                label: "Footer - section home",
                key: "footer_section_home"
            },
            {
                label: "Footer - section services",
                key: "footer_section_services"
            },
            {
                label: "Footer - section about",
                key: "footer_section_about"
            },
            {
                label: "Footer - section news",
                key: "footer_section_news"
            },
            {
                label: "Footer - section staff",
                key: "footer_section_staff"
            },
            {
                label: "Footer - section contact",
                key: "footer_section_contact"
            },
            {
                label: "Footer - section services title",
                key: "footer_section_services_title"
            },
            {
                label: "Footer - copyright",
                key: "footer_copyright"
            },
        ]
    },
    blog: {
        pageName: 'Blog',
        list: [
            {
                label: "Blog - Page prev",
                key: "blog_page_prev"
            },
            {
                label: "Blog - Page next",
                key: "blog_page_next"
            },
            {
                label: "Blog - Page subscribe subtitle",
                key: "blog_page_subscribe_subtitle"
            },
            {
                label: "Blog - Page subscribe title",
                key: "blog_page_subscribe_title"
            },
            {
                label: "Blog - Page subscribe description",
                key: "blog_page_subscribe_description"
            },
            {
                label: "Blog - Page last news",
                key: "blog_page_last_news_title"
            },
            {
                label: "Blog - Page all news",
                key: "blog_page_all_news_title"
            },
            {
                label: "Blog - Page data title",
                key: "blog_pageDetail_data_title"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - Info title",
                key: "contact_info_title"
            },
            {
                label: "Contacts - email title",
                key: "contact_email_title"
            },
            {
                label: "Contacts - number title",
                key: "contact_number_title"
            },
            {
                label: "Contacts - first number",
                key: "contact_first_number"
            },
            {
                label: "Contacts - second number",
                key: "contact_second_number"
            },
            {
                label: "Contacts - third number",
                key: "contact_third_number"
            },
            {
                label: "Contacts - address title",
                key: "contact_address_title"
            },
            {
                label: "Contacts - subtitle",
                key: "contact_subtitle"
            },
            {
                label: "Contacts - title",
                key: "contact_title"
            },
            {
                label: "Contacts - description",
                key: "contact_description"
            },
            {
                label: "Contacts - Page placeholder name",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contacts - Page label name",
                key: "contacts_page_label_name"
            },
            {
                label: "Contacts - Page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "Contacts - Page label lastName",
                key: "contacts_page_label_lastName"
            },
            {
                label: "Contacts - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - Page label email",
                key: "contacts_page_label_email"
            },
            {
                label: "Contacts - Page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - Page label phoneNumber",
                key: "contacts_page_label_phoneNumber"
            },
            {
                label: "Contacts - Page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - Page label message",
                key: "contacts_page_label_message"
            },
            {
                label: "Contacts - Page btn send",
                key: "contacts_page_btn_send"
            },
        ]
    },
}
