//--------------------------------------- Official-server --------------------------------------------
const ADMIN_URL =  "https://app.danksystems.am/admin/api/v1";
export const HOST_MEDIA_URL =  "https://app.danksystems.am/admin";

//--------------------------------------- 4Steps-server --------------------------------------------

// const ADMIN_URL =  "http://173.249.20.192:3551/api/v1";
// export const HOST_MEDIA_URL =  "http://173.249.20.192:3551";

export const _urlJwt = ADMIN_URL + "/jwt";
export const _urlMedia = ADMIN_URL + "/files";
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlContact = ADMIN_URL + "/contacts";
export const _urlModerator = ADMIN_URL + "/moderators";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlProject = ADMIN_URL + "/projects";
export const _urlSubscribers = ADMIN_URL + "/subscribers";
export const _urlMembers = ADMIN_URL + "/members";
export const _urlAbout = ADMIN_URL + "/about";
export const _urlServices = ADMIN_URL + "/services";
export const _urlPartner = ADMIN_URL + "/partners";
export const _urlBlocks = ADMIN_URL + "/blocks";
export const _urlSliders = ADMIN_URL + "/slider";
export const _urlSponsors = ADMIN_URL + "/sponsors";
export const _urlNews = ADMIN_URL + "/news";
export const _urlHome = ADMIN_URL + "/home";

